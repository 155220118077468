import Viewerati from "@quickpoint.me/viewerati"
import { nanoid } from "nanoid";

// is the viewer embedded in the author tool
const isInAuthor = new URLSearchParams(document.location.search).get("inAuthor") ? true: false

// is the viewer in a pop out window from the author tool
const isInPopOut = new URLSearchParams(document.location.search).get("pop") ? true: false
const popHostId = new URLSearchParams(document.location.search).get("hostId");


// pass container element id to Viewerati

const hostId = popHostId ? popHostId : nanoid();

Viewerati({containerSelector:"viewerati", isInAuthor, isInPopOut, isPreviewer: false, hostId: nanoid()});







